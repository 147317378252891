<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Invoice Setting</div>
  </div>
  <div
    class="layout-box-content-section mb-3"
    style="height: calc(-157px + 100vh)"
  >
    <div class="row pb-5">
      <div class="col-lg-4 col-md-4 col-12">
        <div class="custom-form-group">
          <label class="form-label"
            >Select Host<span class="text-danger">*</span></label
          >
          <Multiselect
            v-model="add.host"
            :options="hostList"
            :searchable="true"
            label="label"
            placeholder="Select Host"
            class="multiselect-custom"
            :canClear="true"
            :closeOnSelect="true"
            :object="true"
            noOptionsText="No Result"
          />
          <div class="custom-error" v-if="v$.add.host.$error">
            {{ v$.add.host.$errors[0].$message }}
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-8 col-12">
        <div class="custom-form-group">
          <label class="form-label">Status</label>
          <div class="custom-group-radio-box-btn">
            <input
              type="radio"
              class="btn-check"
              v-model="add.status"
              name="invoice-perday"
              id="perDay"
              autocomplete="off"
              value="1"
            />
            <label
              class="btn custom-radio-box-btn text-capitalize"
              for="perDay"
              >Per Day</label
            >
            <input
              type="radio"
              class="btn-check"
              v-model="add.status"
              name="invoice-perweek"
              id="perWeek"
              autocomplete="off"
              value="2"
            />
            <label
              class="btn custom-radio-box-btn text-capitalize"
              for="perWeek"
              >Per Week</label
            >
            <input
              type="radio"
              class="btn-check"
              v-model="add.status"
              name="invoice-permonth"
              id="perMonth"
              autocomplete="off"
              value="3"
            />
            <label
              class="btn custom-radio-box-btn text-capitalize"
              for="perMonth"
              >Per Month</label
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-5">
      <div class="col-lg-12 col-md-12 col-12 text-center">
        <button
          type="button"
          class="btn modal-bulk-next-btn"
          @click="updateInvoiceGenerationSetting()"
          :disabled="btnloader"
        >
          <span v-if="!btnloader">Submit</span>
          <div
            class="spinner-border text-light custom-spinner-loader-btn"
            role="status"
            v-if="btnloader"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import ApiService from "../../../service/ApiService";
import useValidate from "@vuelidate/core";

export default {
  data() {
    return {
      v$: useValidate(),
      add: {
        status: 3,
      },
      hostList: [],
      btnloader:false,

    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  validations() {
    return {
      add: {
        host: {
          required: helpers.withMessage("Please select host", required),
        },
      },
    };
  },
  mounted() {
    this.getHostList();
  },
  methods: {
    getHostList() {
      this.ApiService.getHostListValues().then((data) => {
        if (data.status == 200) {
          this.hostList = data.data;
          this.loading = false;
        }
      });
    },
    updateInvoiceGenerationSetting() {
      this.v$.add.$validate();
      var formData = new FormData();
      formData.append("ac34", this.add.status);
      formData.append("ac1", this.add.host.value);
      this.ApiService.updateInvoiceGenerationSetting(formData).then((data) => {
        if (data.status == 200) {
          var successMsg = data.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
        } else {
          var errorMsg = data.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
      });
    },
  },
};
</script>

<style></style>